import React from 'react'
import { navigate } from 'gatsby'
import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";
import tw from 'tailwind.macro';
import styled from 'styled-components';
import {
  isAuthenticated,
  isBrowser,
  getAuthInfo
} from '../utils/auth.service';
import Container from '../components/shared/Container'; 

const SharePage = () => {

  if (isBrowser && !isAuthenticated()) {
    navigate('/');
    return null;
  }
  const authInfo = getAuthInfo();
  let sub = null; 
  if (authInfo.sub) {
    sub = authInfo.sub.split('|')[1];
  }
  

  return ( 
    <Layout>
      <SEO title= "Home" />
      <Header>
        <Title>MAKE101 - Share</Title> 
      </Header>
      <Container>
        <p>Sharing is as easy by adding two lines of code to your site: </p>

        <pre style={{overflowY: 'scroll', padding: '0 20px'}}>
          {`<div id="make101_list" data-m101_key=${sub}></div>
<script async src="https://make101.urbanlink.nl/embed/embed.js" charset="utf-8"></script>`}
        </pre>

        <p>You can override the default options by adding the following data properties to the div-element: </p>

        <ul>
          <li>width: minimal 220 (default)</li>
          <li>height: minimal 400 (default)</li>
          <li>sort: title, created(default), random</li>
          <li>filter: none(default), to make, done</li>
        </ul>

        <p>For example: </p>

        <pre style={{overflowY: 'scroll'}}>
          {`<div id="make101_list" 
  data-m01_key="${sub}"
  data-width="400"
  data-height="800"
  data-sort="random"
  data-filter="done"></div>
<script async src="https://make101.urbanlink.nl/embed/embed.js" charset="utf-8"></script>`}
        </pre>

        <p>Did you share your list? Make sure you tweet about it! #make101</p>
        
      </Container>
    </Layout>
  )
}

export default SharePage;

const Header = styled.header`${tw``}`
const Title = styled.h1`${tw`font-bold uppercase`}`
